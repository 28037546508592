import React from 'react';
import { Sidebar } from '../components';
import { FeedbackForm } from '../containers';
import { routesConfig } from '../constants';

export default function FranchisePage() {
  const pages = Object.keys(routesConfig)
    .filter((key) => routesConfig[key].group === 1)
    .map((key) => routesConfig[key]);

  const content = (
    <div className="post mb-10 abs pt-0">
      <div className="card">
        <div className="card-body p-10 pb-0">
          <div className="position-relative mb-10">
            <div className="overlay overlay-show">
              <div
                className="bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-250px"
                style={{ backgroundImage: "url('/assets/media/stock/img-1.jpg')" }}
              />
              <div className="overlay-layer rounded bg-black" style={{ opacity: '0.4' }} />
            </div>
            <div className="position-absolute text-white mb-8 ms-10 me-10 bottom-0">
              <h3 className="text-white fs-3qx fw-bolder mb-3 m">Франшиза</h3>
              <div className="fs-1 fw-bold">Предлагаем открыть собственный магазин SUSHIBOX в вашем городе на условиях франчайзи.</div>
            </div>
          </div>

          <div className="d-flex flex-column">
            <div className="flex-lg-row-auto w-100">
              <div className="card bg-light">
                <div className="card-body">
                  <div className="mb-8">
                    <h4 className="text-gray-700 w-bolder mb-5">Преимущества нашей франшизы</h4>
                    <div className="my-2">
                      <div className="d-flex align-items-center mb-3">
                        <span className="bullet me-3" />
                        <div className="text-gray-600 fw-bold fs-4">Общие инвестиции 700 000 - 1 200 000 рублей</div>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <span className="bullet me-3" />
                        <div className="text-gray-600 fw-bold fs-4">Роялти 2.5 - 3.5% от выручки</div>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <span className="bullet me-3" />
                        <div className="text-gray-600 fw-bold fs-4">Рентабельность 15 - 25%</div>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <span className="bullet me-3" />
                        <div className="text-gray-600 fw-bold fs-4">Первоначальный взнос 100 000 - 150 000 рублей</div>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <span className="bullet me-3" />
                        <div className="text-gray-600 fw-bold fs-4">Срок окупаемости 4 - 10 месяцев</div>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <span className="bullet me-3" />
                        <div className="text-gray-600 fw-bold fs-4">Индивидуальные условия при открытии последующего магазина</div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="bullet me-3" />
                        <div className="text-gray-600 fw-bold fs-4">Относительно небольшой первоначальный капитал для открытия</div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="bullet me-3" />
                        <div className="text-gray-600 fw-bold fs-4">Короткий срок окупаемости</div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="bullet me-3" />
                        <div className="text-gray-600 fw-bold fs-4">
                          Меню простое в приготовлении и доказавшее высокую
                          конкурентоспособность
                          <br />
                          в формате take away
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-0">
                    <h4 className="text-gray-700 w-bolder mb-5">Вы получаете</h4>
                    <div className="my-2">
                      <div className="d-flex align-items-center mb-3">
                        <span className="bullet me-3" />
                        <div className="text-gray-600 fw-bold fs-4">Стандарты всех бизнес-процессов (технологические стандарты приготовления блюд и обслуживания, проведения рекламных компаний)</div>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <span className="bullet me-3" />
                        <div className="text-gray-600 fw-bold fs-4">Поддержку на всех этапах, начиная с подбора помещения для вашего магазина, первоначальное обучение персонала, контроль качества</div>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <span className="bullet me-3" />
                        <div className="text-gray-600 fw-bold fs-4">Корпоративные скидки наших поставщиков</div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="bullet me-3" />
                        <div className="text-gray-600 fw-bold fs-4">Реклама (на сайте и в соцсетях, макеты для проведения эффективных рекламных компаний)</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FeedbackForm />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Sidebar pages={pages} />
      <div className="wrapper d-flex flex-column flex-row-fluid mt-5 mt-lg-10">
        <div className="content flex-column-fluid position-relative">
          <div className="toolbar d-flex flex-stack flex-wrap mb-5 mb-lg-7">
            <div className="page-title d-flex flex-column py-1">
              <h1 className="d-flex align-items-center my-1">
                <span className="text-dark fw-bolder fs-1">Франшиза</span>
              </h1>
            </div>
          </div>
          {content}
        </div>
      </div>
    </>
  );
}
