export const routesConfig = {
  home: { title: 'Главная', href: '/', disableMenu: true },
  menu: { title: 'Меню', href: '/menu', nested: true },
  blog: {
    title: 'Новости', href: '/blog', group: 1, nested: true,
  },
  contacts: { title: 'Доставка и контакты', href: '/contacts', group: 1 },
  franchise: { title: 'Франшиза', href: '/franchise', group: 1 },
  about: { title: 'О нас', href: '/about', group: 1 },
  profile: {
    title: 'Мой профиль', href: '/profile', group: 2, disableMenu: true,
  },
  orders: {
    title: 'Мой заказы', href: '/orders', group: 2, disableMenu: true, nested: true,
  },
  favorites: {
    title: 'Избранное', href: '/favorites', group: 2, disableMenu: true,
  },
  notifications: {
    title: 'Уведомления', href: '/notifications', group: 2, disableMenu: true,
  },
  order: { title: 'Оформление заказа', href: '/order', disableMenu: true },
  policy: {
    title: 'Политика', href: '/policy', group: 1, disableMenu: true,
  },
  apps: {
    title: 'Приложения', href: '/apps', group: 1, disableMenu: true,
  },
};
