/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { setOrderPaymentCash, setOrderPaymentEmail, setOrderPaymentMethod } from '../../../actions';
import { fromStore } from '../../../selectors';
import { Input } from '../../../components';
import { EMAIL_REG_EXP } from '../../../constants';

const options = [
  { value: 'terminal', label: 'Терминал (картой при получении)' },
  { value: 'cash', label: 'Наличные' },
  { value: 'online', label: 'Онлайн-оплата' },
];

const optionsCash = [
  { value: 'no', label: 'Под расчет' },
  { value: 'c1000', label: 'Сдача с 1000 ₽' },
  { value: 'c1500', label: 'Сдача с 1500 ₽' },
  { value: 'c2000', label: 'Сдача с 2000 ₽' },
  { value: 'c5000', label: 'Сдача с 5000 ₽' },
];

export function PaymentStep() {
  const dispatch = useDispatch();
  const method = useSelector(fromStore.orderPaymentMethodSelector);
  const email = useSelector(fromStore.orderPaymentEmailSelector);
  const cash = useSelector(fromStore.orderPaymentCashSelector);
  const point = useSelector(fromStore.orderDeliveryPointSelector);
  const zone = useSelector(fromStore.orderDeliveryZoneDataSelector);
  const deliveryMethod = useSelector(fromStore.orderDeliveryMethodSelector);
  const user = useSelector(fromStore.userSelector);

  const availablePaymentMethod = options.filter((item) => {
    if (item.value !== 'online') return true;
    if (!user) return false;
    if (deliveryMethod === 'own' && point && point.onlinePay && point.yookassa) return true;
    if (deliveryMethod === 'delivery' && zone && zone.point && zone.point.onlinePay && zone.point.yookassa) return true;

    return false;
  });

  const onChangeMethod = (item) => {
    dispatch(setOrderPaymentMethod(item.value));
  };

  const onChangeCash = (item) => {
    dispatch(setOrderPaymentCash(item.value));
  };

  const onChangeEmail = (item) => {
    dispatch(setOrderPaymentEmail(item));
  };

  return (
    <div className="current min-h-300px">
      <div className="w-100">
        <div className="pb-10">
          <h2 className="fw-bolder text-dark">Выберите способ оплаты</h2>
        </div>

        <div className="mb-10">
          <label className="form-label required">Способ оплаты</label>
          <Select
            value={availablePaymentMethod.find((item) => item.value === method)}
            className="react-select-container"
            classNamePrefix="react-select"
            options={availablePaymentMethod}
            placeholder="Выберите способ оплаты"
            onChange={onChangeMethod}
          />
        </div>
        {method === 'cash' && (
          <div className="mb-10">
            <label className="form-label">Сдача</label>
            <Select
              value={optionsCash.find((item) => item.value === cash)}
              className="react-select-container"
              classNamePrefix="react-select"
              options={optionsCash}
              placeholder="Выберите сдачу"
              onChange={onChangeCash}
            />
          </div>
        )}
        {
          method === 'online' && (
            <div className="col-md-8">
              <Input
                title="E-mail (для отправки чека)"
                placeholder="Введите e-mail..."
                minLength={1}
                maxLength={50}
                onChange={onChangeEmail}
                value={email}
                error={email && !EMAIL_REG_EXP.test(email) ? 'Введен некорректный E-mail' : undefined}
              />
            </div>
          )
        }
      </div>

    </div>
  );
}
