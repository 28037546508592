import dayjs from 'dayjs';
import { handleActions } from 'redux-actions';
import { baseUrl } from '../constants';

const getNewPrice = (price, condition, value, persent) => {
  if (!price) return 0;
  if (condition === 'equal') return value;
  if (persent) {
    const persentPrice = Math.round(price * (value / 100));
    if (condition === 'plus') {
      return price + persentPrice;
    }
    return price - persentPrice;
  }
  if (condition === 'plus') {
    return price + value;
  }
  if (condition === 'multiply') {
    return price * value;
  }
  return price - value;
};

export const identityPayload = (state, action) => {
  if (action.payload !== undefined) return action.payload;
  return state;
};

export const identityClean = (initial) => () => initial;

export const forAction = (action, handler) => [action, handler];
export const forCleanAction = (action, handler) => [action, handler];

export const createCleanActions = (actions, initial) => actions.map(
  (action) => forCleanAction(action, identityClean(initial)),
);

export const createReducer = (actions, initial, cleanActions = []) => {
  const cleanActionsHandlers = createCleanActions(cleanActions, initial);

  return handleActions(new Map([...actions, ...cleanActionsHandlers]), initial);
};

export const propNameSelector = (selector, field) => (state) => selector(state)[field];

export const regexpPhone = (phone) => {
  const tPhone = phone
    .replace(/\s/g, '')
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/-/g, '')
    .replace(/\+/g, '');
  return tPhone;
};

export const makeActionType = (namespace, items) => `SB/${namespace}/${items.join('/')}`;

export const numberDevider = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const declOfNum = (number, titles, onlyTitle) => {
  const cases = [2, 0, 1, 1, 1, 2];
  const result = (number % 100 > 4 && number % 100 < 20)
    ? 2
    : cases[(number % 10 < 5)
      ? number % 10
      : 5];

  if (onlyTitle) return titles[result];
  return `${numberDevider(number)} ${titles[result]}`;
};

export const formatPhone = (value) => {
  const strValue = `${value}`;
  return `+7 (${strValue.slice(1, 4)}) ${strValue.slice(4, 7)}-${strValue.slice(7, 9)}-${strValue.slice(9, 11)}`;
};

export const sortOrderByDate = (a, b) => new Date(b.created_at) - new Date(a.created_at);

export const getTimeWork = ({
  date = new Date(),
  minTime = 1,
  maxTime = 25,
  delivery = 'delivery',
  timeMethod = 'fast',
  selectedTime = new Date(),
}) => {
  const currentTime = dayjs(date);
  const currentHour = currentTime.hour();
  const currentMinutes = currentTime.minute();

  if (currentHour < minTime || currentHour >= maxTime) {
    return { isWork: false, message: `Время работы: c ${minTime}:00 по ${maxTime}:00` };
  }

  if (timeMethod === 'fast') {
    if (delivery === 'delivery') {
      if (currentHour < minTime
            || (currentHour === maxTime - 1 && currentMinutes > 45) || currentHour >= maxTime) {
        return { isWork: false, message: `Время работы доставки: c ${minTime}:00 по ${maxTime - 1}:45` };
      }
    }
  } else {
    const selectedTimeDate = dayjs(selectedTime);
    const selectedHour = selectedTimeDate.hour();
    const selectedmenutes = selectedTimeDate.minute();

    if (delivery === 'delivery') {
      if (selectedHour < minTime
        || (selectedHour === maxTime - 1 && selectedmenutes > 45) || selectedHour >= maxTime) {
        return { isWork: false, message: `Время работы доставки: c ${minTime}:00 по ${maxTime - 1}:45` };
      }
    } else if (selectedHour < minTime || selectedHour >= maxTime) {
      return { isWork: false, message: `Время работы: c ${minTime}:00 по ${maxTime}:00` };
    }
  }
  return { isWork: true, message: '' };
};

export const getImageUrl = (image, host) => `${host || ''}${baseUrl}${image?.url}`;
export const getGoodUrl = (category, host) => `${host}/menu/${category}`;

export const getModifiedGood = (good, idMod) => {
  const mod = good.modifications
    && good.modifications.find((item) => String(item.id) === String(idMod));
  if (!mod) return good;

  const modified = { ...good };
  if (mod.price) {
    modified.price = getNewPrice(modified.price, mod.condition, mod.price, false);
  }
  if (mod.oldPrice) {
    modified.oldPrice = getNewPrice(modified.oldPrice, mod.condition, mod.oldPrice, false);
  }
  if (mod.weight) {
    modified.weight = mod.weight;
  }

  modified.isDefault = mod.default;
  modified.activeMod = mod.id;
  modified.activeModName = mod.title;

  return modified;
};
