import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Img } from 'react-image';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveModalAction } from '../../actions';
import { fromStore } from '../../selectors';
import { Loader, PizzaSizer } from '../../components';
import {
  getIdGood, getIdModGood, getImageUrl, getModifiedGood, numberDevider,
} from '../../utils';
import { AddCartButton } from './AddCartButton';
import { GoodPrice } from './GoodPrice';
import { FavotiteButton } from './FavotiteButton';
import { MEASURE } from '../../constants';

export function PizzaCard({ id }) {
  const dispatch = useDispatch();
  const itemId = getIdGood(id);
  const item = useSelector(fromStore.goodsByIdSelector(itemId));
  const { modifications = [] } = item;
  const defaultModification = modifications.find((mod) => mod.id === item.activeMod);
  const [activeMod, setActiveMod] = useState(defaultModification);
  const cartId = activeMod ? getIdModGood(activeMod, itemId) : id;
  const good = activeMod ? getModifiedGood(item, activeMod.id) : item;
  const weight = `${MEASURE[item.measure].title}: ${numberDevider(good.weight)} ${MEASURE[item.measure].value}`;
  const weightdop = item.weightdop && item.measuredop ? `${MEASURE[item.measuredop].title}: ${numberDevider(item.weightdop)} ${MEASURE[item.measuredop].value}` : null;

  const onClickDetail = () => {
    dispatch(setActiveModalAction({ field: 'detail', value: itemId }));
  };

  const renderImage = useMemo(() => (
    <div className="text-center">
      <Img
        alt={item.name}
        src={getImageUrl(item.image)}
        className="mw-100 card-rounded"
        loader={<Loader image />}
      />
    </div>
  ), [item.image]);

  if (item.isStop) return null;

  return (
    <div key={id} onClick={onClickDetail} aria-hidden className="col-xl-4">
      <div className="card mb-xl-8 border-hover-dark cursor-pointer card-xl-stretch">
        <div className="card-header border-0 pt-5">
          <div className="d-flex flex-stack w-100 align-items-start">
            <h3 className="card-title align-items-start flex-column m-0">
              <span className="card-label fw-bolder text-dark">{item.name}</span>
              <div className="d-flex align-items-center mt-2">
                <span className="badge badge-dark">{weight}</span>
                {weightdop && <span className="badge badge-secondary ms-1">{weightdop}</span>}
                {item.hot && <img title="Острое" className="ms-1 w-15px" src="/assets/media/pepper.png" alt="острое" />}
              </div>
            </h3>
            <FavotiteButton id={String(item.id)} />
          </div>
        </div>
        <div className="card-body pt-5 d-flex flex-column justify-content-between h-100">
          {renderImage}
          {activeMod && (
            <div className="text-center mt-4">
              <PizzaSizer
                items={modifications}
                selected={activeMod}
                onChange={(value) => setActiveMod(value)}
              />
            </div>
          )}
          <div className="d-flex flex-stack mt-10">
            <GoodPrice price={good.price} oldPrice={good.oldPrice} id={String(item.id)} />
            <AddCartButton isStop={item.isStop} id={cartId} />
          </div>
        </div>
      </div>
    </div>
  );
}

PizzaCard.propTypes = {
  id: PropTypes.string.isRequired,
};
